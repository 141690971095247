import styles from './SectionTwo.module.css';
import { Link } from 'react-router-dom';

const SectionTwo = () => {
    return(
        <div className={styles.background}>  
            <div className={styles.position}>
                <img className={styles.wheel} src="tu_mundo.gif" alt="" />
                <div className={styles.textContainer}>
                    <p className={styles.text}>
                        COCINA DE AUTOR ELABORADA  
                         CON INGREDIENTES NATURALES 
                         Y ENFOQUE CIRCULAR 
                        {/* <Link className={styles.link} to={`/About`} >
                            <span className={styles.plus}>+</span>
                        </Link> */}
                    </p>
                    <p className={styles.description}>
                        Descubrí la magia de la cocina circular con Rezuma.
                        Productos prácticos, ricos y responsables, 
                        llevados directamente a la puerta de tu casa.<br/>
                        <span className={styles.bold}>¡Sumate a la revolución con cada bocado!</span>
                    </p>
                </div>    
            </div>
            <p className={styles.sectionFooter}>
                SOPAS DE AUTOR <span className={styles.punto}>.</span> PARA DESCONGELAR, CALENTAR Y DISFRUTAR <span className={styles.punto}>.</span> 
                <img className={styles.reFacil} src="Refacil.png" alt="" />
            </p>
        </div>
    );
}

export default SectionTwo;
