import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './NavBarMinimized.module.css';

const NavBarMinimized = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const kitsCompra = useSelector((state) => state.kitsCompra);
    const totalSopas = kitsCompra.reduce((total, kit) => total + kit.length, 0);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.background}>
            <div className={styles.backgroundContent}>
                <div className={styles.dropdown} onClick={toggleDropdown} ref={dropdownRef}>
                    <img className={styles.carrito} src="iconos_header_burger (1).png" alt="Menu" />
                    {isDropdownOpen && (
                        <div className={styles.dropdownMenu}>
                            <Link className={styles.dropdownItem} to="/KitsSopas">Kits Sopas</Link>
                            <Link className={styles.dropdownItem} to="/Shop">Armá tu Kit</Link>
                            <Link className={styles.dropdownItem} to="/About">Rezuma</Link>
                        </div>
                    )}
                </div>
                <Link to="/">
                    <img className={`${styles.title}`} src="/logo_rezuma.png" alt="Logo" />
                </Link>
                <div className={styles.carritoWrapper}>
                    <Link to="/Carrito">
                        <img className={styles.carrito} src="iconos_header_carrito.png" alt="Cart" />
                        {totalSopas > 0 && <span className={styles.cartCount}>{totalSopas}</span>}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NavBarMinimized;
