import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addSoup, removeSoup, sendEmail } from '../../reducer/actions';
import { Link } from 'react-router-dom';
import MercadoPago from '../MercadoPago/MercadoPago';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Carrito.module.css';
import Footer from '../Footer/Footer';
import FooterMobile from '../Footer/FooterMobile';
import NavBarMobile from '../NavBar/NavBarMobile';
import NavBarMinimized from '../NavBar/NavBarMinimized ';
import axios from 'axios';
import { Toast, ToastContainer } from 'react-bootstrap';

const Carrito = () => {
  const dispatch = useDispatch();
  const kitsCompra = useSelector((state) => state.kitsCompra);
  const kits = useSelector((state) => state.kits);
  const [responseData, setResponseData] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [error, setError] = useState({
    phone: '',
    postalCode: '',
    address: '', 
    email: ''
  });

  const initialBillingInfo = JSON.parse(localStorage.getItem('billingInfo')) || {
    name: '',
    surname: '',
    phone: '',
  };
  const initialShippingInfo = JSON.parse(localStorage.getItem('shippingInfo')) || {
    postalCode: '',
    email: '',
    street: '',
    address: '',
  };

  const [billingInfo, setBillingInfo] = useState(initialBillingInfo);
  const [shippingInfo, setShippingInfo] = useState(initialShippingInfo);

  const [billingComplete, setBillingComplete] = useState(false);
  const [shippingComplete, setShippingComplete] = useState(false);
  const [preferenceId, setPreferenceId] = useState(null);
  const [showMercadoPago, setShowMercadoPago] = useState(false);

  useEffect(() => {
    const { name, surname, phone } = billingInfo;
    setBillingComplete(name && surname && phone);
    localStorage.setItem('billingInfo', JSON.stringify(billingInfo));
  }, [billingInfo]);

  useEffect(() => {
    const { postalCode, email, street, address } = shippingInfo;
    setShippingComplete(postalCode && email && street );
    localStorage.setItem('shippingInfo', JSON.stringify(shippingInfo));
  }, [shippingInfo, error]);

  const handleAddSoup = () => {
    dispatch(addSoup());
  };

  const handleRemoveSoup = (kitIndex, soupIndex) => {
    dispatch(removeSoup(kitIndex, soupIndex));
  };

  const handleBillingInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'phone') {
      if (!/^\d{10}$/.test(value)) {
        setError((prevError) => ({
          ...prevError,
          phone: 'el campo de teléfono debe contener código de área y teléfono (sin el 15)'
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          phone: ''
        }));
      }
    }
    setBillingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handleShippingInputChange = (event) => {
    const { name, value } = event.target;
  
    if (name === 'postalCode') {
      if (/^[^\d]*$/.test(value)) {
        setError((prevError) => ({
          ...prevError,
          postalCode: 'El campo de código postal solo debe contener números.'
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          postalCode: ''
        }));
      }
    }
    if (name === 'email') {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        setError((prevError) => ({
          ...prevError,
          email: 'El campo de email solo debe contener email.'
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          email: ''
        }));
      }
    }  else if (name === 'address') {
      // Cambia el error a 'address' y permite solo números
      if (/^\d*$/.test(value)) {
        setError((prevError) => ({
          ...prevError,
          address: ''
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          address: 'El campo de número de casa solo debe contener números.'
        }));
      }
    }
  
    setShippingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }));
  };
  
  

  const stepOneComplete = kitsCompra && kitsCompra.length > 0 && kitsCompra.every((kit) => kit.length === 5);
  const stepTwoComplete = billingComplete;
  const stepThreeComplete = shippingComplete;
  const isCartComplete = stepOneComplete && billingComplete && shippingComplete;

  useEffect(() => {
    if (isCartComplete && showMercadoPago) {

    }
  }, [isCartComplete, showMercadoPago]);
  

  const numberOfKits = kitsCompra.length;
  const shippingCost = numberOfKits >= 2 ? 0 : 3000; // Envío gratis si se compran más de 2 kits
 /*  console.log(shippingCost) */
  let total;

  total = kitsCompra.reduce((sum, kit) => {
    return sum + kit.reduce((kitSum, soup) => kitSum + soup.price, 0);
  }, 0) + shippingCost; // Agregar costo de envío al total

  const existingKit = kits.find((kit) => 
    kitsCompra.some((compraKit) =>
      JSON.stringify(kit.products) === JSON.stringify(compraKit)
    )
  );
  
  if (existingKit) {
    /* console.log(total) */
    const to = (total - shippingCost) * 10 / 100;  // Calcula el 10% del total
    total = total - to;  // Resta el 10% del total original
  
  }

  const orderData = {
    title: 'ReZuma',
    quantity: 1,
    unit_price: total,
    initialBillingInfo,
    initialShippingInfo,
    kitsCompra, 
    shippingCost
  };
  /* console.log(kitsCompra.length) */

  

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!isCartComplete){
      setToastMessage(` Por favor, completa todos los campos obligatorios y asegúrate de haber cargado 5 sopas antes de proceder con el envío`);
      setShowToast(true);
    } 
    setShowMercadoPago(true);
    const response = await axios.post('https://rezuma.onrender.com/MercadoPago/create_preference', orderData);
    setResponseData(response.data);
    
  };

 /*  console.log(kitsCompra)   */

  return (
    <div className={styles.cartContainer}>
      <ToastContainer 
        className={`position-fixed p-3 ${styles.customtoastcontainer}`} 
        style={{ top: '35%', right: '35%' }}>
        <Toast className={styles.customtoastcontainer2} onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide >
          <Toast.Header>
            <strong className="me-auto">imprevisto al generar el pago</strong>
          </Toast.Header>
         <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </ToastContainer>
      {window.innerWidth <= 800 || window.innerHeight <= 590 ? (
        <div>
          <NavBarMobile />
        </div>
      ) : (
        <div>
          <NavBarMinimized />
        </div>
      )}

      <div className="container mt-5">
        <div className="row mb-4">
          <div className="col-12">
            <div className={styles['step-container']}>
              <div className={`${styles.step} ${stepOneComplete ? styles.complete : styles.incomplete}`}>
                1 
                <div className={styles.stepLabel}>elegí tus productos</div>
              </div>
              
              <div className={`${styles.step} ${stepTwoComplete ? styles.complete : styles.incomplete}`}>
                2
                <div className={styles.stepLabel}>completá tus datos de contacto</div>
              </div>
              <div className={`${styles.step} ${stepThreeComplete ? styles.complete : styles.incomplete}`}>
                3
                <div className={styles.stepLabel}>completá los datos de envio</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`row ${styles.color}`}>
          <div className={`row ${styles.color2} col-lg-6 mb-4`}>
            <div className= {`p-4 border-0 ${styles.color}`}>
              
              <h3 className="mb-4">Datos de Contacto</h3>
              <input type="text" name="name" className="form-control mb-2" placeholder="Nombre" value={billingInfo.name} onChange={handleBillingInputChange} />
              <input type="text" name="surname" className="form-control mb-2" placeholder="Apellido" value={billingInfo.surname} onChange={handleBillingInputChange} />
              <input type="text" name="phone" className="form-control mb-2" placeholder="Teléfono" value={billingInfo.phone} onChange={handleBillingInputChange} />
              {error.phone && <div className={styles.error}>{error.phone}</div>}

              <h3 className={`mt-4 mb-4 border-0 ${styles.color}`}>Información de Envío</h3>
              <input type="text" name="postalCode" className="form-control mb-2" placeholder="Código Postal" value={shippingInfo.postalCode} onChange={handleShippingInputChange} />
              {error.postalCode && <div className={styles.error}>{error.postalCode}</div>}
              <input type="email" name="email" className="form-control mb-2" placeholder="Email" value={shippingInfo.email} onChange={handleShippingInputChange} />
              {error.email && <div className={styles.error}>{error.email}</div>}
              <input type="text" name="street" className="form-control mb-2" placeholder="Calle, altura, piso/depto, entrecalles y barrio" value={shippingInfo.street} onChange={handleShippingInputChange} />
             {/*  <input type="text" name="address" className="form-control mb-2" placeholder="Numero de casa" value={shippingInfo.address} onChange={handleShippingInputChange} />
              {error.address && <div className={styles.error}>{error.address}</div>} */}
              {/* <img className={styles.imagen} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1722391430/reZuma/img_carrito_1_ggrzmh.png" alt="" /> */}
              <div className={styles.sectionp}>
                <p className={styles.p}>Envios CABA: Lunes de 10 hs. a 14 hs.</p>
                <p className={styles.p}>Envios Zona Norte: Miercoles de 10 hs. a 14 hs.</p>
                <p className={styles.p}>Pedidos hasta 24 horas antes del día de envío, de lo contrario se envían la próxima semana.</p>
              </div>
              
              
                  <button
                  className={styles.button1}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Comprar
                  </button>
                
                 {
             window.innerWidth <= 820 || window.innerHeight <= 590 ? <div>
              {showMercadoPago && isCartComplete && responseData && <MercadoPago key={responseData || Date.now()} data={responseData} orderData={orderData} />}
             </div>
              : 
              <div className={styles.t}>
                {showMercadoPago && isCartComplete && responseData && <MercadoPago key={responseData || Date.now()} data={responseData} orderData={orderData} />}
              </div>
              
        
          }
            </div>
          </div>

          <div className="col-lg-6">
            <div className= {`p-4 border-0 ${styles.color}`}>
              <h2 className="mb-4">Carrito</h2>
              {kitsCompra.map((kit, kitIndex) => (
                <div key={kitIndex} className="mb-4">
                  {kit.length > 0 && (
                    <>
                      <h3>Kit {kitIndex + 1}</h3>
                      {kit.map((soup, soupIndex) => (
                        <div key={soupIndex} className="d-flex align-items-center mb-3 border-bottom pb-2">
                          <img src={soup.images[0]} alt={soup.name}  className="border-0 img-thumbnail me-3" style={{ width: '100px', height: 'auto', background:'#E8E1D9' }} />
                          <div className="me-3">
                            <span className="fw-bold">{soup.name}</span>
                          </div>
                          <span className="me-3">${soup.price}</span>
                          <button onClick={() => handleRemoveSoup(kitIndex, soupIndex)} className= {`btn btn-danger ${styles.color3}`} >X</button>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              ))}
              { 
                stepOneComplete ? <div></div> : <p>DEBES AGREGAR UN TOTAL DE 5 SOPAS PARA COMPLETAR EL KIT</p>
                
              }
              {shippingCost === 0 ? (
            <h4 className="text-start text-success">¡Envío Gratis!</h4>
          ) : (
            <h4 className="text-start">Costo de Envío: ${shippingCost}</h4>
          )}
              <div className={styles.total}>
                <h4>Total: ${total}</h4>
              </div>
              <div className={styles.buttons}>
                <Link to="/Shop">
                  <button onClick={handleAddSoup} className={styles.button2}>Agregar Sopa</button>
                </Link>
              </div> 
            </div>
          </div>
        </div>
        
      </div>

      {window.innerWidth <= 1120 || window.innerHeight <= 590 ? (
        <div className={styles.footer}>
          <FooterMobile />
        </div>
      ) : (
        <div className={styles.footer}>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Carrito;
