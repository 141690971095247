// reducer.js
import { GETALL, GET_PRODUCT_BY_ID, CREATE_KIT, GET_ALL_KITS, ADD_TO_KIT, ADD_KIT_TO_CART, POST_ORDER, GET_PEDIDOS, LIMPIAR_CARRITO, ELIMINAR_PRODUCTO, DELETE_KIT } from './actionTypes';

const initialState = {
  products: [],
  product: {},
  kits: [],
  kitsCompra: [[]],
  orders: [],
  prices: ''
};

const noteReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL:
      return {
        ...state,
        products: action.payload
      };

      case LIMPIAR_CARRITO:
        /* console.log('reducer'); */
        return {
          ...state,
          kitsCompra: [], // Asegúrate de que este es el estado deseado
        };
     

    case GET_PRODUCT_BY_ID:
      return {
        ...state,
        product: action.payload,
      };

    case GET_ALL_KITS:
      return {
        ...state,
        kits: action.payload,
      };

    case CREATE_KIT:
      return {
        ...state,
        kits: [...state.kits, action.payload],
      };

    case ADD_TO_KIT: {
      const updatedKits = [...state.kitsCompra];
      let added = false;

      // Intentar agregar la sopa al primer kit disponible que tenga menos de 5 sopas
      for (let i = 0; i < updatedKits.length; i++) {
        if (updatedKits[i].length < 5) {
          updatedKits[i] = [...updatedKits[i], action.payload];
          added = true;
          break;
        }
      }

      // Si todos los kits tienen 5 sopas, crear un nuevo kit
      if (!added) {
        updatedKits.push([action.payload]);
      }

      return {
        ...state,
        kitsCompra: updatedKits,
      };
    }

    case ADD_KIT_TO_CART: {
      const updatedKits = [...state.kitsCompra];
      const kitToAdd = action.payload;
    
      kitToAdd.products.forEach(product => {
        let added = false;
    
        // Intentar agregar la sopa al primer kit disponible que tenga menos de 5 sopas
        for (let i = 0; i < updatedKits.length; i++) {
          if (updatedKits[i].length < 5) {
            updatedKits[i] = [...updatedKits[i], product];
            added = true;
            break;
          }
        }
    
        // Si todos los kits tienen 5 sopas, crear un nuevo kit
        if (!added) {
          updatedKits.push([product]);
        }
      });
    
      // Verificar si el kit recién agregado es igual a uno existente en 'kits'
      const newKit = kitToAdd.products;
      const existingKit = state.kits.find(kit =>
        JSON.stringify(kit.products) === JSON.stringify(newKit)
      );
    
      if (existingKit) {
        // Si existe un kit idéntico, notificar con el precio del kit
        /* alert(`El kit que intentas agregar ya existe. Precio: $${existingKit.price}`); */
        /* let price = action.payload.prices */
      }
    

      return {
        ...state,
        kitsCompra: updatedKits,
        prices: action.payload.price
      };
    }
    

    case 'REMOVE_SOUP': {
      const { kitIndex, soupIndex } = action.payload;
      const updatedKits = state.kitsCompra.map((kit, index) =>
        index === kitIndex ? kit.filter((_, i) => i !== soupIndex) : kit
      );
      return { ...state, kitsCompra: updatedKits.filter(kit => kit.length > 0) };
    }

    case 'INCREASE_SOUP_QUANTITY': {
      const { kitIndex, soupIndex } = action.payload;
      const updatedKits = state.kitsCompra.map((kit, index) => {
        if (index === kitIndex) {
          return kit.map((soup, i) => {
            if (i === soupIndex) {
              return { ...soup, quantity: (soup.quantity || 1) + 1 };
            }
            return soup;
          });
        }
        return kit;
      });

      return {
        ...state,
        kitsCompra: updatedKits,
      };
    }

    case 'DECREASE_SOUP_QUANTITY': {
      const { kitIndex, soupIndex } = action.payload;
      const updatedKits = state.kitsCompra.map((kit, index) => {
        if (index === kitIndex) {
          return kit.map((soup, i) => {
            if (i === soupIndex) {
              return { ...soup, quantity: Math.max((soup.quantity || 1) - 1, 0) };
            }
            return soup;
          });
        }
        return kit;
      });

      return {
        ...state,
        kitsCompra: updatedKits,
      };
    }

    case GET_PEDIDOS:
      return {
        ...state,
        orders: action.payload,
      };

     

    default:
      return state;
  }
};

export default noteReducer;
