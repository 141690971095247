import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './NavBar.module.css';

const NavBar = () => {
    const [isShrunk, setIsShrunk] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const kitsCompra = useSelector((state) => state.kitsCompra);
    const totalSopas = kitsCompra.reduce((total, kit) => total + kit.length, 0);

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsShrunk(true);
        } else {
            setIsShrunk(false);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`${styles.background} ${isShrunk ? styles.shrink : ''}`}>
            <div className={`${styles.backgroundContent}`}>
                <div className={styles.dropdown} onClick={toggleDropdown} ref={dropdownRef}>
                    <img className={`${styles.carrito} ${isShrunk ? styles.shrink : ''}`} src="iconos_header_burger (1).png" alt="Menu" />
                    {isDropdownOpen && (
                        <div className={styles.dropdownMenu}>
                            <Link className={styles.dropdownItem} to="/KitsSopas">Nuestros Kits</Link>
                            <Link className={styles.dropdownItem} to="/Shop">Armá tu Kit</Link>
                            <Link className={styles.dropdownItem} to="/About">Rezuma</Link>
                        </div>
                    )}
                </div>
                <Link to="/">
                    <img className={`${styles.title} ${isShrunk ? styles.shrink : ''}`} src="/logo_rezuma.png" alt="Logo" />
                </Link>
                <div className={styles.carritoWrapper}>
                    <Link to="/Carrito">
                        <img className={`${styles.carrito} ${isShrunk ? styles.shrink : ''}`} src="iconos_header_carrito.png" alt="Carrito" />
                        {totalSopas > 0 && <span className={styles.cartCount}>{totalSopas}</span>}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NavBar;
