import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import styles from './CreateSoupKits.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct, createKit, getAllKits } from '../../reducer/actions';

const CreateSoupKits = () => {
  const dispatch = useDispatch();
  const products = useSelector(state => state.products || []);
  const kits = useSelector(state => state.kits || []);
  const [selectedSoups, setSelectedSoups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [kitName, setKitName] = useState('');
  const [kitImage, setKitImage] = useState('');
  const [kitPrice, setKitPrice] = useState(''); // Nuevo estado para el precio del kit

  useEffect(() => {
    dispatch(getProduct());
    dispatch(getAllKits()); // Obtener todos los kits al montar el componente
  }, [dispatch]);

  const handleSelectSoup = (soup) => {
    setSelectedSoups([...selectedSoups, soup]);
  };

  const handleDeselectSoup = (index) => {
    const newSelectedSoups = [...selectedSoups];
    newSelectedSoups.splice(index, 1);
    setSelectedSoups(newSelectedSoups);
  };

  const handleCreateKit = () => {
    setShowModal(true);
  };

  const handleConfirmCreateKit = () => {
    const kitData = {
      name: kitName,
      image: kitImage,
      price: kitPrice, // Incluir el precio en los datos del kit
      products: selectedSoups.map(soup => soup.id)
    };
    dispatch(createKit(kitData));
    setShowModal(false);
    setSelectedSoups([]);
    setKitName('');
    setKitImage('');
    setKitPrice(''); // Limpiar el precio después de crear el kit
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setKitImage(reader.result); // Almacena la cadena base64 de la imagen
    };
    reader.readAsDataURL(file); // Lee el archivo como una URL de datos (base64)
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Crear Kit de Sopas</h2>
      <div className={styles.soupsList}>
        {products.length > 0 ? (
          products.map((soup) => (
            <div key={soup.id} className={styles.soupItem}>
              <img src={`${soup.images[0]}`} alt={soup.name} className={styles.soupImage} />
              <div className={styles.soupInfo}>
                <h4 className={styles.soupName}>{soup.name}</h4>
                <button className={styles.selectButton} onClick={() => handleSelectSoup(soup)}>
                  Seleccionar
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No hay sopas disponibles.</p>
        )}
      </div>
      <div className={styles.selectedSoups}>
        <h3 className={styles.subtitle}>Sopas Seleccionadas</h3>
        {selectedSoups.map((soup, index) => (
          <div key={index} className={styles.selectedSoupItem}>
            <span>{index + 1}. {soup.name}</span>
            <button className={styles.deselectButton} onClick={() => handleDeselectSoup(index)}>
              Deseleccionar
            </button>
          </div>
        ))}
      </div>
      {selectedSoups.length >= 5 && (
        <button className={styles.createKitButton} onClick={handleCreateKit}>
          Crear Kit
        </button>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Ingresar detalles del Kit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="kitName">
              <Form.Label>Nombre del Kit</Form.Label>
              <Form.Control type="text" value={kitName} onChange={(e) => setKitName(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="kitImage">
              <Form.Label>Imagen del Kit</Form.Label>
              <Form.Control type="file" onChange={handleImageChange} />
            </Form.Group>
            <Form.Group controlId="kitPrice"> {/* Nuevo input para el precio */}
              <Form.Label>Precio del Kit</Form.Label>
              <Form.Control
                type="number"
                value={kitPrice}
                onChange={(e) => setKitPrice(e.target.value)}
                placeholder="Ingrese el precio del kit"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleConfirmCreateKit}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateSoupKits;
