import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllKits, deleteKit } from '../../reducer/actions'; // Ajusta la ruta según la ubicación real de tus acciones
import styles from './KitList.module.css';

const KitList = () => {
  const dispatch = useDispatch();
  const kits = useSelector(state => state.kits);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllKits()); // Al montar el componente, obtén todos los kits
  }, [dispatch]);

  const handleDeleteKit = async (id) => {
    await dispatch(deleteKit(id)); // Despacha la acción para eliminar el kit
    dispatch(getAllKits()); // Despacha la acción para obtener todos los kits nuevamente
  };

  return (
    <div className={styles.kitList}>
      <h2>Lista de Kits</h2>
      {kits.map(kit => (
        <div key={kit.id} className={styles.kit}>
          <img src={kit.image} alt={kit.name} className={styles.kitImage} />
          <p className={styles.kitName}>{kit.name}</p>
          <button 
            className={styles.deleteButton}
            onClick={() => handleDeleteKit(kit.id)}
          >
            Eliminar
          </button>
        </div>
      ))}
    </div>
  );
};

export default KitList;
