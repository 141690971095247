import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NavBar from "../NavBar/NavBar"; 
import styles from './Home.module.css';
import SectionTwo from "../SectionTwo/SectionTwo";
import SectionThree from "../SectionThree/SectionThree";
import SectionFour from "../SectionFour/SectionFour";
import SectionFive from '../SectionFive/SectionFive2';
import Footer from '../Footer/Footer';
import NavBarMobile from "../NavBar/NavBarMobile";
import SectionTwoMobile from "../SectionTwo/SectionTwoMobile";
import SectionFourMobile from "../SectionFour/SectionFourMobile";
import SectionFiveMobile from "../SectionFive/SectionFiveMobile";
import FooterMobile from '../Footer/FooterMobile';
import { getProduct } from '../../reducer/actions';
import SectionThreeMobile from '../SectionThree/SectionThreeMobile';

const Home = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getProduct());
    }, [dispatch]);

    const initialChatBot = () => {
        // Verifica si el script ya está cargado
        const v = document.createElement('script'), s = document.getElementsByTagName('script')[0];
        v.onload = function() {
            window.voiceflow.chat.load({
                verify: { projectID: '668e9cb5e553723965c07720' },
                url: 'https://general-runtime.voiceflow.com',
                versionID: 'production'
            }).then(() => {
                // Abre el chat automáticamente cuando se cargue
                window.voiceflow.chat.open();
            });
        };
        v.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
        v.type = "text/javascript";
        s.parentNode.insertBefore(v, s);
    }

    return (
        <>
            

            <img 
                onClick={() => initialChatBot()} 
                src="https://cm4-production-assets.s3.amazonaws.com/1722370294930-6day.png" 
                className={styles.buttonChatBot}
                alt='chatBot'
            />
            {
                window.innerWidth <= 820 || window.innerHeight <= 500 ?  
                <div className={styles.backGround}>
                    <NavBarMobile />
                    <div className={styles.imagen1}></div>
                    <SectionTwoMobile />
                    <SectionThreeMobile />
                    <SectionFiveMobile />
                    <SectionFourMobile />
                    <FooterMobile />
                </div>
                :
                <div className={styles.backGround}>
                    <NavBar />
                    <div className={styles.imagen2}></div>
                    <SectionTwo />
                    <SectionThree />
                    <SectionFive />
                    <SectionFour />
                    <Footer />
                </div>
            }
        </>
    );
}

export default Home;
