
export const GETALL = 'GETALL';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const CREATE_KIT = 'CREATE_KIT';
export const GET_ALL_KITS = 'GET_ALL_KITS';
export const ADD_TO_KIT = 'ADD_TO_KIT';
export const ADD_KIT_TO_CART = 'ADD_KIT_TO_CART'; 
export const POST_ORDER = 'POST_ORDER';
export const GET_PEDIDOS = 'GET_PEDIDOS';
export const LIMPIAR_CARRITO = 'LIMPIAR_CARRITO';
export const ELIMINAR_PRODUCTO = 'ELIMINAR_PRODUCTO';
export const DELETE_KIT = 'DELETE_KIT';
export const SENDEMAIL = 'SENDEMAIL';

