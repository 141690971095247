import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPedidos } from '../../reducer/actions';
import styles from './TraePedidos.module.css';

const TraePedidos = () => {
  const dispatch = useDispatch();
  const pedidos = useSelector((state) => state.orders);

  useEffect(() => {
    dispatch(getPedidos());
  }, [dispatch]);

console.log(pedidos)
  return (
    <div className={styles.pedidosContainer}>
      <h2>Pedidos</h2>
      {pedidos && pedidos.length > 0 ? (
        pedidos.map((pedido) => (
          <div key={pedido.id} className={styles.card}>
            <p><strong>Nombre:</strong> {pedido.items.initialBillingInfo.name}</p>
            <p><strong>Teléfono:</strong> {pedido.items.initialBillingInfo.phone}</p>
            <p><strong>Estado de Sopas:</strong></p>
            <ul>
              {pedido.items.kitsCompra.map((kit, index) => (
                <li key={index}>
                  {kit.map((sopa, sopaIndex) => (
                    <p key={sopaIndex}>{sopa.name}</p>
                  ))}
                </li>
              ))}
            </ul>
            <p><strong>Dirección de Envío:</strong></p>
            <p>Calle: {pedido.items.initialShippingInfo.street}</p>
            <p>Código Postal: {pedido.items.initialShippingInfo.postalCode}</p>
            <p>Email: {pedido.items.initialShippingInfo.email}</p>
          </div>
        ))
      ) : (
        <p>No hay pedidos disponibles.</p>
      )}
    </div>
  );
};

export default TraePedidos;
