import React, { useState } from 'react';
import styles from './DashboardMobile.module.css';
import CreateProductForm from '../CrearProducto/CrearProducto';
import ProductList from '../ProductList/ProductList';
import CreateSoupKits from '../CreateSoupKits/CreateSoupKits';
import KitList from '../KitList/KitList';
import TraePedidos from '../TraePedidos/TraePedidos';

const DashboardMobile = () => {
  const [showCreateProductForm, setShowCreateProductForm] = useState(false);
  const [showProductList, setShowProductList] = useState(false);
  const [showCreateSoupKits, setShowCreateSoupKits] = useState(false);
  const [showKitList, setShowKitList] = useState(false);
  const [showPedidos, setShowPedidos] = useState(false);

  const toggleCreateProductForm = () => {
    setShowCreateProductForm(!showCreateProductForm);
    hideAllExcept('showCreateProductForm');
  };

  const toggleProductList = () => {
    setShowProductList(!showProductList);
    hideAllExcept('showProductList');
  };

  const toggleCreateSoupKits = () => {
    setShowCreateSoupKits(!showCreateSoupKits);
    hideAllExcept('showCreateSoupKits');
  };

  const toggleKitList = () => {
    setShowKitList(!showKitList);
    hideAllExcept('showKitList');
  };

  const hideAllExcept = (stateKey) => {
    if (stateKey !== 'showCreateProductForm') setShowCreateProductForm(false);
    if (stateKey !== 'showProductList') setShowProductList(false);
    if (stateKey !== 'showCreateSoupKits') setShowCreateSoupKits(false);
    if (stateKey !== 'showKitList') setShowKitList(false);
    if (stateKey !== 'showPedidos') setShowPedidos(false);
  };

  const togglePedidos = () => {
    setShowPedidos(!showPedidos);
    setShowCreateProductForm(false);
    setShowProductList(false);
    setShowCreateSoupKits(false);
    setShowKitList(false);
  };
 
  return (
    <div className={styles.background}>
      <div className={styles.dashboard}>
        <h1 className={styles.title}>Dashboard</h1>
        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={toggleCreateProductForm}>
            Crear Producto
          </button>
          <button className={styles.button} onClick={toggleProductList}>
            {showProductList ? 'Ocultar Productos' : 'Traer Productos'}
          </button>
          <button className={styles.button} onClick={toggleCreateSoupKits}>
            Crear Kit de Sopas
          </button>
          <button className={styles.button} onClick={toggleKitList}>
            {showKitList ? 'Ocultar Kits' : 'Mostrar Kits'}
          </button>
          <button className={styles.button} onClick={togglePedidos}>
                {showPedidos ? 'Ocultar Pedidos' : 'Traer Pedidos'}
            </button>
        </div>
        {showCreateProductForm && <CreateProductForm />}
        {showProductList && <ProductList />}
        {showCreateSoupKits && <CreateSoupKits />}
        {showKitList && <KitList />}
        {showPedidos && <TraePedidos />}
      </div>
    </div>
  );
};

export default DashboardMobile;
