import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './KitsSopas.module.css';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import NavBarMobile from '../NavBar/NavBarMobile';
import NavBar from '../NavBar/NavBar';
import { getAllKits, addKitToCart } from '../../reducer/actions'; // Importar la acción
import Footer from '../Footer/Footer';
import { Toast, ToastContainer } from 'react-bootstrap'; // Asegúrate de importar estos componentes

const KitsSopas = () => {
    const dispatch = useDispatch();
    const kits = useSelector(state => state.kits); // Asegurando que kits siempre sea un array
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getAllKits());
    }, [dispatch]);

    const handleAddKitToCart = (kit) => {
        dispatch(addKitToCart(kit));
        setToastMessage(`El kit "${kit.name}" ha sido agregado al carrito`);
        setShowToast(true);
    };

    const renderKits = (kits) => {
        return kits.map(kit => {
            const totalKitPrice = kit.products.reduce((sum, product) => sum + product.price, 0);

            return (
                <div key={kit.id} className={`${styles.cardContainer}`}>
                    <div className={`card ${styles.card}`}>
                        <div className={styles.imageContainer}>
                            <img src={kit.image} className={`card-img-top ${styles.productImage}`} alt={kit.name} />
                        </div>
                        <div className={`card-body ${styles['card-body']}`}>
                            <h5 className={`card-title ${styles['card-title']}`}>{kit.name}</h5>
                            {kit.products && kit.products.map((product, index) => (
                                <p key={index} className={styles.productName}>{product.name}</p>
                            ))}
                            <p className={styles.price}>Precio: ${kit.price}</p>
                            <div className={styles.buttonGroup}>
                                <Button className={styles.button2} variant="success" onClick={() => handleAddKitToCart(kit)}>
                                    COMPRAR
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <>
            <div className={styles.background}>
                {window.innerWidth <= 800 || window.innerHeight <= 590 ? <NavBarMobile /> : <NavBar />}
                <div className={styles.section}>
                    <img className={styles.resopa} src="re_kits.png" alt="Sopa" />
                </div>
                <div className={styles.container}>
                    <div className={styles.kitsContainer}>
                        {renderKits(kits)}
                    </div>
                </div>
                <div className="col-12 mb-4 d-flex justify-content-center">
                    <Button className={styles.mainButton} variant="success" style={{ width: '70%' }}>
                        <Link to="/Shop" className={styles.linkButton2}>
                            Armá Tu Kit
                        </Link>
                    </Button>
                </div>
            </div>

            {/* ToastContainer */}
            <ToastContainer
                className={`position-fixed p-3 ${styles.customtoastcontainer}`}
                style={{ top: '10%', right: '10%' }}
            >
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Kit Agregado</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

            <Footer />
        </>
    );
}

export default KitsSopas;
