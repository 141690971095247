import React, { useEffect, useState } from 'react';
import styles from './NewShop.module.css'; // Asegúrate de que el archivo esté importado correctamente
import { Link } from 'react-router-dom';
import NavBarMobile from '../NavBar/NavBarMobile';
import NavBar from '../NavBar/NavBar';
import { getProduct, getProductById, addToKit } from '../../reducer/actions';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Toast, ToastContainer } from 'react-bootstrap';
import diez from '../../assets/10.avif'

const NewShop = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const kits = useSelector((state) => state.kits);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getProduct());
  }, [dispatch]);

  const handleAddToKit = (product) => {
    dispatch(addToKit(product));
    setToastMessage(`${product.name} agregado al kit`);
    setShowToast(true);
  };

  const renderProducts = (products) => {
    return products.map((product) => (
      <div key={product.id} className="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div className={`${styles.card}`}>
          <img
            src={product.images[0] || 'placeholder.jpg'}
            className={`${styles.productImage}`}
            alt={product.name}
          />
          <div className={`card-body ${styles['card-body']}`}>
            <h5 className={`${styles['card-title']}`}>{product.name}</h5>
            <p className={`card-text ${styles['card-text']}`}>
              Precio: ${product.price}
            </p>
            <div className={styles.buttonGroup}>
              <button className={styles.button1} onClick={() => handleAddToKit(product)}>
                AGREGAR AL KIT
              </button>
              <button className={styles.button2}>
                <Link className={styles.link} to={`/Detail`} onClick={() => dispatch(getProductById(product.id))}>
                  MÁS INFO
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className={styles.background}>
        {window.innerWidth <= 1120 || window.innerHeight <= 590 ? (
          <NavBarMobile />
        ) : (
          <NavBar />
        )}
        <div className={styles.section}>
          <img
            className={styles.resopa}
            src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1725567807/reZuma/10_gyji2o.avif"
            alt="Sopa"
          />
        </div>
        <div className={`container-fluid ${styles.container}`}>
          <div className="row row-cols-1 row-cols-md-4 g-4">
            <div className="col-md-12 mb-4">
              <h2 className={styles.text}>ARMÁ TU KIT CON TUS SOPAS PREFERIDAS</h2>
            </div>
            {renderProducts(products.slice(0, 4))}
          </div>
          <div className="row row-cols-1 row-cols-md-4 g-4">
            {renderProducts(products.slice(4))}
          </div>
        </div>
        <div className="col-12 mb-4 d-flex justify-content-center">
          <Link to="/KitsSopas" className={styles.mainButton} style={{ width: '70%' }}>
            Ver kits prearmados
          </Link>
        </div>
      </div>
      <ToastContainer 
        className={`position-fixed p-3 ${styles.customtoastcontainer}`} 
        style={{ top: '10%', right: '10%' }}>
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Agregaste productos a tu carrito</strong>
          </Toast.Header>
         <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </ToastContainer>



      <Footer />
    </>
  );
};

export default NewShop;
