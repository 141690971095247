import React, { useState } from 'react';
import styles from './DetailProductMobile.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Collapse } from 'reactstrap';
import NavBarMobile from '../NavBar/NavBarMobile';
import { useSelector, useDispatch } from 'react-redux'; // Importa useDispatch
import { useNavigate } from 'react-router-dom';
import { addToKit } from '../../reducer/actions'; // Importa la acción addToKit

const DetailProductMobile = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const products2 = useSelector((state) => state.product);
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Hook de dispatch

  const toggle1 = () => setIsOpen1(!isOpen1);
  const toggle2 = () => setIsOpen2(!isOpen2);

  const handleBackClick = () => {
    navigate('/Shop');
  };

  const handleTouchStart = () => {
    handleBackClick();
  };

  const handleAddToCart = () => {
    dispatch(addToKit(products2)); // Despacha la acción para añadir el producto al kit
  };

  /* console.log(products2); */

  return (
    <div className={styles.container}>
      <NavBarMobile />
      <div className={styles.nav}>
        {/* <div className={styles.backButton} onTouchStart={handleTouchStart}>
          <div onTouchStart={handleTouchStart}>BACK</div>
        </div> */}
      </div>
      <div className={styles.content}>
        <div className={styles.photo}>
          {products2.images && <img src={products2.images[0]} alt={products2.name} className={styles.img} />}
        </div>
        <div className={styles.description}>
          <h2>{products2.name}</h2>
          <p>{products2.description}</p>
          <h1>${products2.price}</h1>
          <div className={styles.addCart} onClick={handleAddToCart}>AGREGAR AL CARRITO</div>
          <div className={styles.menuDiv} onClick={toggle1}>¿Cómo se regenera?</div>
          <Collapse isOpen={isOpen1}>
            <div className={styles.collapseContent}>
              <img className={styles.conservar} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1722338842/reZuma/iconos_regenera_brgk5q.png" alt="" />
            </div>
          </Collapse>
          <div className={styles.menuDiv} onClick={toggle2}>¿Cómo se conserva?</div>
          <Collapse isOpen={isOpen2}>
            <div className={styles.collapseContent}>
              <img className={styles.conservar2} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1722338845/reZuma/iconos_conserva_vhjqvp.png" alt="" />
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default DetailProductMobile;
