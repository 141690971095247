import styles from './SectionFiveMobile.module.css';
import { Link } from 'react-router-dom';
import 'animate.css';
import React, { useState, useEffect } from 'react';
import four from '../../assets/4.avif';
import fourR from '../../assets/5.avif';
import six from '../../assets/6.avif';
import seven from '../../assets/7.avif';
import eigth from '../../assets/8.avif';
import nine from '../../assets/9.avif';

const SectionFiveMobile = () => {
    const [animate, setAnimate] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimate(prevAnimate => !prevAnimate);
        }, 7000); // 7 segundos

        // Limpieza del intervalo cuando el componente se desmonta
        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.backgroundd}>  
            <img className={styles.reKits} src="re_kits.png" alt="" />
            <p className={styles.text2}>
                Conocé nuestros kits <br/>
                o armá tu propio kit con 5 sopas a elección
            </p>
            <div className={styles.position}>
                <div className={styles.flipContainer}>
                    <div className={styles.flipper}>
                        <img className={`${styles.image2} ${styles.front} ${animate ? '' : ''}`} src={four} alt="" loading='lazy'/>
                        <img className={`${styles.image2} ${styles.back}`} src={fourR} alt="" loading='lazy'/>
                    </div>
                </div>
                <div className={styles.text}>
                    <p>
                        SOPA DE CALABAZA Y PERA <br />
                        SOPA DE CEBOLLAS CARAMELIZADAS <br />
                        SOPA CREMA DE LECHUGA <br />
                        SOPA DE PAPA Y PUERRO <br />
                        SOPA DE APIO Y MANZANA
                    </p>             
                    <Link to='/KitsSopas'><p className={styles.kits}>VER KITS</p></Link>               
                </div>    

                <div className={styles.flipContainer}>
                    <div className={styles.flipper}>
                        <img className={`${styles.image2} ${styles.front} ${animate ? '' : ''}`} src={six} alt="" loading='lazy' />
                        <img className={`${styles.image2} ${styles.back}`} src={seven} alt="" loading='lazy'/>
                    </div>
                </div>
                <div className={styles.text}>
                    <p>
                        SOPA CREMA LENTEJAS Y ESPINACA  <br />
                        SOPA DE CALABAZA Y PERA <br />
                        SOPA DE ZANAHORIA ESPECIADA  <br />
                        SOPA VERDURAS DE LA HUERTA  <br />
                        SOPA DE CEBOLLA CARAMELIZADA
                    </p>             
                    <Link to='/KitsSopas'><p className={styles.kits}>VER KITS</p></Link>               
                </div>    

                <div className={styles.flipContainer}>
                    <div className={styles.flipper}>
                        <img className={`${styles.image2} ${styles.front} ${animate ? '' : ''}`} src={eigth} alt="" loading='lazy'/>
                        <img className={`${styles.image2} ${styles.back}`} src={nine} alt="" loading='lazy'/>
                    </div>
                </div>
                <div className={styles.text}>
                    <p>
                        ARMÁ TU KIT: <br />
                        SELECCIONANDO 5 SOPAS <br />
                        <br />        
                        <br /><br />      
                    </p>            
                    <Link to='/KitsSopas'><p className={styles.kits}>VER MÁS</p></Link>               
                </div>    
            </div>
        </div>  
    );
}

export default SectionFiveMobile;
