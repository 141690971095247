import { GETALL, GET_PRODUCT_BY_ID, CREATE_KIT, GET_ALL_KITS, ADD_TO_KIT, ADD_KIT_TO_CART, POST_ORDER, GET_PEDIDOS, LIMPIAR_CARRITO, ELIMINAR_PRODUCTO, DELETE_KIT, SENDEMAIL }  from './actionTypes';

import axios from 'axios';



export const createProduct = (payload) => {
    return async function (dispatch) {
      try {
        const data = await axios.post('https://rezuma.onrender.com/Products', payload);
        /* console.log(payload) */
        return dispatch({ type: GETALL, payload: data.data });
      } catch (error) {
        return (error.response.data);
      }
    };
}

export const getProduct = () => {
  return async function (dispatch) {
    try {
      const data = await axios.get('https://rezuma.onrender.com/Products');
      /* console.log(data) */
      return dispatch({ type: GETALL, payload: data.data });
    } catch (error) {
      return (error.response.data);
    }
  };
}

export const getProductById = (productId) => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`https://rezuma.onrender.com/Products/${productId}`);
      const product = response.data;
      /* console.log(product) */
      return dispatch({ type: GET_PRODUCT_BY_ID, payload: product });
    } catch (error) {
      console.error('Error fetching product by ID:', error);
      // Aquí podrías manejar el error de alguna manera apropiada, como dispatch de otro tipo de acción para errores.
    }
  };
};

export const createKit = (payload) => {
  return async function (dispatch) {
    try {
      const response = await axios.post('https://rezuma.onrender.com/Kits', payload);
    /*   console.log(response) */
      return dispatch({ type: CREATE_KIT, payload: response.data });
    } catch (error) {
      return (error.response.data,' ---------->');
    }
  };
};

export const getAllKits = () => {
  return async function (dispatch) {
    try {
      const response = await axios.get('https://rezuma.onrender.com/kits');
      /* console.log(response.data) */
      return dispatch({ type: GET_ALL_KITS, payload: response.data });
    } catch (error) {
      return (error.response.data);
    }
  };
};

export const addToKit = (product) => {
/*   console.log(product) */
  return {
    type: ADD_TO_KIT,
    payload: product,
  };
};


export const removeSoup = (kitIndex, soupIndex) => ({
  type: 'REMOVE_SOUP',
  payload: { kitIndex, soupIndex },
});

export const addSoup = () => ({
  type: 'ADD_SOUP',
})

// actions.js


export const increaseSoupQuantity = (kitIndex, soupIndex) => ({
  type: 'INCREASE_SOUP_QUANTITY',
  payload: { kitIndex, soupIndex },
});

export const decreaseSoupQuantity = (kitIndex, soupIndex) => ({
  type: 'DECREASE_SOUP_QUANTITY',
  payload: { kitIndex, soupIndex },
});

export const addKitToCart = (kit) => ({
  type: ADD_KIT_TO_CART,
  payload: kit,
});

export const postOrder = async (orderData) => {
  const response = await axios.post('https://rezuma.onrender.com/Order', orderData);
  /* console.log(response.data) */
  /* return async function (dispatch) {
    try {
      console.log(orderData, ' <----------<---------')
      const response = await axios.get('http://localhost:3001/Order', orderData);
      console.log(response.data)
      return dispatch({ type: POST_ORDER, payload: response.data });
    } catch (error) {
      return (error.response.data);
    }
  }; */
};

export const getPedidos = () => {
  return async function (dispatch) {
    try {
      const response = await axios.get('https://rezuma.onrender.com/Order');
      console.log(response.data)
      return dispatch({ type: GET_PEDIDOS, payload: response.data });
      
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
};


/* export const limpiarCarrito = (kit) => ({
  type: LIMPIAR_CARRITO,
}); */

export const limpiarCarrito = () => {
 /*  console.log('holis') */
  return ({
    type: LIMPIAR_CARRITO, payload: 'hola'
  });
};
/* export const limpiarCarrito = () => {
  console.log('holis')
  return async function (dispatch) {
    try {
      return dispatch({ type: LIMPIAR_CARRITO });
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
}; */


export const ElimiarProducto = (id) => {
  /* console.log(id) */
  return async function (dispatch) {
    try {
      const response = await axios.delete(`https://rezuma.onrender.com/Products/${id}`);
    /*   console.log(response.data, '  <---------------------') */
      return dispatch({ type: ELIMINAR_PRODUCTO , payload: response.data });
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
};

export const deleteKit = (id) => async (dispatch) => {
  /* console.log(id) */
  try {
    await axios.delete(`https://rezuma.onrender.com/kits/${id}`); // Llama a la API para eliminar el kit
    dispatch({
      type: DELETE_KIT,
      payload: id,
    });
  } catch (error) {
    console.error("Error deleting kit:", error);
  }
};

export const sendEmail = (orderData) => async (dispatch) => {
  try {
    /* console.log(orderData) */
    await axios.post(`https://rezuma.onrender.com/SendEmail`, orderData); // Llama a la API para eliminar el kit
  } catch (error) {
    console.error("Error deleting kit:", error);
  }
};
