import styles from './SectionThreeMobile.module.css';
import three from '../../assets/3.avif'

const SectionThreeMobile = () => {
    return(
        <div className={styles.backgroundd}>  
        
            <img className={styles.image} src={three} alt="" loading='lazy'/>
            <section className={styles.section}>
                <p className={styles.title}>SOPAS DE AUTOR</p>
                <p className={styles.text}>
                Te presentamos nuestra primer línea de productos: prácticos kits de sopas de autor congeladas. Podés elegir entre el Kit Temporada, Kit Gourmet o Armá Tu Kit con 5 sopas a elección. 
                <p className={styles.solucion}>    Una re-solución para alimentar tu día a día.</p>
                
                </p>
                
            </section>
        </div>  
    )
}

export default SectionThreeMobile;
