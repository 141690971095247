import React, { useState } from 'react';
import styles from './DashBoard.module.css';
import CreateProductForm from '../CrearProducto/CrearProducto';
import ProductList from '../ProductList/ProductList';
import CreateSoupKits from '../CreateSoupKits/CreateSoupKits';
import KitList from '../KitList/KitList';
import TraePedidos from '../TraePedidos/TraePedidos';
import DashboardMobile from './DashboardMobile';

const Dashboard = () => {
  const [showCreateProductForm, setShowCreateProductForm] = useState(false);
  const [showProductList, setShowProductList] = useState(false);
  const [showCreateSoupKits, setShowCreateSoupKits] = useState(false);
  const [showKitList, setShowKitList] = useState(false);
  const [showPedidos, setShowPedidos] = useState(false);

  const toggleCreateProductForm = () => {
    setShowCreateProductForm(!showCreateProductForm);
    setShowProductList(false);
    setShowCreateSoupKits(false);
    setShowKitList(false);
    setShowPedidos(false);
  };

  const toggleProductList = () => {
    setShowProductList(!showProductList);
    setShowCreateProductForm(false);
    setShowCreateSoupKits(false);
    setShowKitList(false);
    setShowPedidos(false);
  };

  const toggleCreateSoupKits = () => {
    setShowCreateSoupKits(!showCreateSoupKits);
    setShowCreateProductForm(false);
    setShowProductList(false);
    setShowKitList(false);
    setShowPedidos(false);
  };

  const toggleKitList = () => {
    setShowKitList(!showKitList);
    setShowCreateProductForm(false);
    setShowProductList(false);
    setShowCreateSoupKits(false);
    setShowPedidos(false);
  };

  const togglePedidos = () => {
    setShowPedidos(!showPedidos);
    setShowCreateProductForm(false);
    setShowProductList(false);
    setShowCreateSoupKits(false);
    setShowKitList(false);
  };

  return (
    <div className={styles.background}>
      {
        window.innerWidth <= 1120 || window.innerHeight <= 590 ? (
          <DashboardMobile />
        ) : (
          <div className={styles.dashboard}>
            <h1 className={styles.title}>Dashboard</h1>
            <div className={styles.buttonContainer}>
              <button className={styles.button} onClick={toggleCreateProductForm}>
                Crear Producto
              </button>
              <button className={styles.button} onClick={toggleProductList}>
                {showProductList ? 'Ocultar Productos' : 'Traer Productos'}
              </button>
              <button className={styles.button} onClick={toggleCreateSoupKits}>
                Crear Kit de Sopas
              </button>
              <button className={styles.button} onClick={toggleKitList}>
                {showKitList ? 'Ocultar Kits' : 'Mostrar Kits'}
              </button>
              <button className={styles.button} onClick={togglePedidos}>
                {showPedidos ? 'Ocultar Pedidos' : 'Traer Pedidos'}
              </button>
            </div>
            {showCreateProductForm && <CreateProductForm />}
            {showProductList && <ProductList />}
            {showCreateSoupKits && <CreateSoupKits />}
            {showKitList && <KitList />}
            {showPedidos && <TraePedidos />}
          </div>
        )
      }
    </div>
  );
};

export default Dashboard;
