import styles from './About.module.css';
import NavBar from '../NavBar/NavBar';
import NavBarMobile from '../NavBar/NavBarMobile';
import Footer from '../Footer/Footer';
import FooterMobile from '../Footer/FooterMobile';
import React, { useEffect } from 'react';

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div /* className={styles.b} */>
            {
                window.innerWidth <= 1120 || window.innerHeight <= 590 ?   
                <>
                    <NavBarMobile/>
                    <img className={styles.b3} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1722561685/reZuma/Seccion_Rezuma-04_1_mpacyz.jpg" alt="" />
                    <FooterMobile/>
                </> 
                :
                <>
                    <NavBar/>
                    <img className={styles.b2} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1722561362/reZuma/Seccion_Rezuma-05_1_l8v1tb.jpg" alt="" />
                    <Footer/>
                </> 
            }
           
        </div>
                
    )
}

export default About;
