import styles from './Footer.module.css'
/* import img from '../../assets/images' */

const Footer = () => {
    return(
        <div className={styles.background}>  
            <img className={styles.logo} src="logo_footer.png" alt="" />
            <div className={styles.seguinos}>
                <span>SEGUINOS</span>
                <span><a target='_blank' href="https://www.tiktok.com/@rezumasabores?_t=8oFBbm0M5jW&_r=1"> <img className={styles.etiquetas} src="iconos_rrss_tiktok.png" alt="" /> </a></span>
                
                <span><a target='_blank' href="https://www.instagram.com/rezumasabores?igsh=MWlqb3cwb3ZzdHNvZg%3D%3D&utm_source=qr"><img className={styles.etiquetas} src="iconos_rrss_ig.png" alt="" /></a></span>
                
                <span> <a target='_blank' href="https://www.facebook.com/profile.php?id=61562496544935">  <img className={styles.fb} src="iconos_rrss_fb.png" alt="" /> </a> </span>
            </div>
        </div>
    )
}

export default Footer;
