import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './SectionFour.module.css';
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SectionFour = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isHovering, setIsHovering] = useState(false);
    const visibleSlides = 4;

    // Obtiene el estado de products desde Redux
    const products = useSelector(state => state.products);
    const totalSlides = products.length;

    const handleSlideChange = (event) => {
        setCurrentSlide(event);
    };

    useEffect(() => {
        const slider = document.querySelector('.carousel__slider');
        const observer = new MutationObserver(() => {
            const activeSlide = parseInt(slider.querySelector('.carousel__slide--visible').dataset.index, 10);
            setCurrentSlide(activeSlide);
        });

        observer.observe(slider, { attributes: true, subtree: true, attributeFilter: ['class'] });

        return () => observer.disconnect();
    }, []);

    const getSlideClass = (index) => {
        const centralSlides = getCentralSlides(currentSlide, visibleSlides, totalSlides);
        return centralSlides.includes(index) ? styles.middleSlide : '';
    };

    const getCentralSlides = (current, visible, total) => {
        const centralIndices = [];
        const halfVisible = Math.floor(visible / 2);
        for (let i = -halfVisible + 1; i <= halfVisible; i++) {
            centralIndices.push((current + i + total) % total);
        }
        return centralIndices;
    };

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    return (
        <div className={styles.background}>
            <img className={styles.resopa} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1725567807/reZuma/10_gyji2o.avif" loading='lazy' alt="Sopa" />
            <p className={styles.text}>
                Conocé nuestras sopas de autor.<br />
                Congeladas, Naturales y listas para disfrutar.
            </p>
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={60}
                totalSlides={totalSlides}
                visibleSlides={visibleSlides}
                infinite
                isPlaying={!isHovering}
                interval={3000}
                lockOnWindowScroll
                dragEnabled
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className={styles.b}>
                    <ButtonBack className={`${styles.carouselButton} ${styles.carouselButtonBack}`}>
                        <FaChevronLeft />
                    </ButtonBack>
                    <Slider className={styles.slider}>
                        {products.map((product, index) => (
                            <Slide
                                key={index}
                                index={index}
                                className={`${styles.slide} ${getSlideClass(index)}`}
                            >
                                <div className={styles.flipCard}>
                                    <div className={styles.flipCardInner}>
                                        <div className={styles.flipCardFront}>
                                            <img className={styles.image} src={product.images[1]} alt={`Sopa ${index + 1}`} />
                                        </div>
                                        <div className={styles.flipCardBack}>
                                            <img className={styles.image} src={product.images[2]} alt={`Sopa ${index + 1} - back`} />
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        ))}
                    </Slider>
                    <ButtonNext className={`${styles.carouselButton} ${styles.carouselButtonNext}`}>
                        <FaChevronRight />
                    </ButtonNext>
                </div>
            </CarouselProvider>
            <Link to={'/KitsSopas'}>
                <p className={styles.kit}>¡QUIERO MI KIT DE SOPAS!</p>
            </Link>
        </div>
    );
};

export default SectionFour;
