import React from 'react';
import styles from './SectionFive2.module.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const SectionFive = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.background}>
      <img src="re_kits.png" alt="Kits" />
      <p>Conocé nuestros kits o armá tu propio kit con 5 sopas a elección</p>
      <div className={styles.section}>
        <div className={styles.kit}>
          <div className={styles.imageContainer}>
            <img
              src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1725566320/reZuma/4_1_re1c64.avif"
              alt="Kit Temporada"
              loading='lazy'
              className={styles.frontImage}
            />
            <img
              src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1725566518/reZuma/5_tqdmvu.avif"
              alt="Kit Reverso"
              loading='lazy'
              className={styles.backImage}
            />
          </div>
          <p>
            SOPA CREMA LENTEJAS Y ESPINACA <br />
            SOPA DE CALABAZA Y PERA  <br />
            SOPA DE ZANAHORIA ESPECIADA <br />
            SOPA VERDURAS DE LA HUERTA  <br />
            SOPA DE CEBOLLA CARAMELIZADA
          </p>
          <Link to='/KitsSopas' className={styles.kitButton}>VER MAS</Link>
        </div>
        <div className={styles.kit}>
          <div className={styles.imageContainer}>
            <img
              src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1725566855/reZuma/6_bvufsc.avif"
              alt="Kit Gourmet 1"
              loading='lazy'
              className={styles.frontImage}
            />
            <img
              src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1725567095/reZuma/7_qf3rlu.avif"
              alt="Kit Reverso"
              loading='lazy'
              className={styles.backImage}
            />
          </div>
          <p>
            SOPA DE CALABAZA Y PERA <br />
            SOPA DE CEBOLLAS CARAMELIZADAS <br />
            SOPA CREMA DE LECHUGA <br />
            SOPA DE PAPA Y PUERRO <br />
            SOPA DE APIO Y MANZANA <br />
          </p>
          <Link to='/KitsSopas' className={styles.kitButton}>VER MAS</Link>
        </div>
        <div className={styles.kit}>
          <div className={styles.imageContainer}>
            <img
              src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1725567288/reZuma/8_gdt9jr.avif"
              alt="Kit Gourmet 2 Reverso"
              loading='lazy'
              className={styles.frontImage}
            />
            <img
              src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1725567464/reZuma/9_ggly9h.avif"
              alt="Kit Reverso"
              loading='lazy'
              className={styles.backImage}
            />
          </div>
          <p className={styles.text3}>ARMÁ TU KIT: SELECCIONANDO TUS <br /> 5 SOPAS PREFERIDAS</p>
          <Link to='/KitsSopas' className={styles.kitButton}>VER MAS</Link>
        </div>
      </div>
    </div>
  );
};

export default SectionFive;
