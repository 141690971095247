import React, { useState, useEffect } from 'react';
import styles from './DetailProduct.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Collapse } from 'reactstrap';
import NavBarMobile from '../NavBar/NavBarMobile';
import NavBarMinimized from '../NavBar/NavBarMinimized ';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {  addToKit } from '../../reducer/actions';
import DetailProductMobile from '../DetailProduct/DetailProductMobile';

const DetailProduct = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const products2 = useSelector((state) => state.product);

/*   console.log(products2.images) */
  
 
  const imagen1 = products2.images
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product.product); // Obtener el producto del estado de Redux

  const toggle1 = () => setIsOpen1(!isOpen1);
  const toggle2 = () => setIsOpen2(!isOpen2);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth' // Puedes usar 'auto' para un desplazamiento instantáneo
    });
    
  }, []);

/*   useEffect(() => {
    // Llamar a la acción para obtener los detalles del producto por su ID
    dispatch(getProductById('id_del_producto_a_consultar')); // Reemplaza 'id_del_producto_a_consultar' con el ID adecuado
  }, [dispatch]); // Asegúrate de pasar dispatch como dependencia */

  const handleAddToKit = (product) => {
    dispatch(addToKit(product));
  };

  return (
    <div className={styles.s}>
      <NavBarMinimized />
      {
        window.innerWidth <= 1120 || window.innerHeight <= 590 ? <DetailProductMobile/> : <div className={styles.background}>
          
        <div className={`${styles.card} ${scrollY > 0 ? styles.scrolled : ''}`}>
          
          <div className={styles.section}>
            <div className={styles.photo}>
              {
                products2.images ? <img className={styles.img} src={imagen1[0]} alt={products2 ? products2.name : ''} /> : <div></div>
              }
              
            </div>
            <div className={styles.description}>
              <h2 className={styles.soupTitle}>{products2 ? products2.name : 'Loading...'}</h2>
              <section className={styles.section2}>
                <h4 className={styles.section2}>Ingredientes: </h4>
                <p>{products2 ? products2.description : 'Loading...'}</p>
              </section>
              <div className={styles.masMenos}>
                <span>+</span><span>+</span>
                <h1>${products2 ? products2.price : 'Loading...'}</h1>
                
              </div>
              <div className={styles.but} onClick={() => handleAddToKit(products2)} >AGREGAR AL CARRITO</div>

              <div className={styles.menuDiv} onClick={toggle1}>¿Cómo se regenera?</div>
              <Collapse isOpen={isOpen1}>
                <div className={styles.collapseContent}>
                 <img className={styles.conservar} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1722338842/reZuma/iconos_regenera_brgk5q.png" alt="" />
                </div>
              </Collapse>

              <div className={styles.menuDiv} onClick={toggle2}>¿Cómo se conserva?</div>
              <Collapse isOpen={isOpen2}>
                <div className={styles.collapseContent}>
                  <img className={styles.conservar} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1722338845/reZuma/iconos_conserva_vhjqvp.png" alt="" />
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
      }
      
    </div>
  );
};

export default DetailProduct;
