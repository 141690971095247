import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import  Home  from './components/Home/Home';
import Dashboard from './components/DashBoard/DashBoard';
import NewShop from './components/NewShop/NewShop';
import DetailProduct from './components/DetailProduct/DetailProduct';
import KitsSopas from './components/KitsSopas/KitsSopas';
import Carrito from './components/Carrito/Carrito';
import About from './components/About/About';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Shop" element={<NewShop />} />
        <Route path="/Detail" element={<DetailProduct />} />
        <Route path="/KitsSopas" element={<KitsSopas />} />
        <Route path="/Carrito" element={<Carrito />} />
        <Route path="/About" element={<About />} />
      </Routes>
    </Router>
  );
}

export default App;
