import styles from './SectionTwoMobile.module.css';
import { Link } from 'react-router-dom';

const SectionTwoMobile = () => {
    return(
        <div className={styles.background}>  
        <div className={styles.postion}>
            {/* <img className={styles.wheel} src="tu_mundo.gif" alt="" /> */}
            <div className={styles.text2}>
                <p className={styles.text}>
                    COCINA DE AUTOR ELABORADA <br/>
                    CON INGREDIENTES NATURALES <br/>
                     Y ENFOQUE CIRCULAR 
                   {/*  <Link className={styles.link} to={`/About`} > <span className={styles.plas}>+</span><br/>
                    </Link> */}
                    
                </p>
                <p className={styles.descubri}>
                    Descrubrí la magia de la cocina circular con Rezuma.
                    Productos prácticos, ricos y responsables, llevados 
                    directamente a la puerta de tu casa.<br/>
                    <span className={styles.bold}>¡Sumate a la revolución con cada bocado!</span>
                </p>
            </div>    
        </div>
       {/*  <p className={styles.sectionFooter}>SOPAS LISTAS <span className={styles.punto}>.</span> para calentar en microondas o en agua hirviendo <span className={styles.punto}>.</span> <img className={styles.reFacil} src="Refacil.png" alt="" /></p> */}
       <img className={styles.tirita} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1722626447/reZuma/tirita_mobile_2_wbuj0e.gif" alt="" />
        </div>
          
    )
}

export default SectionTwoMobile;
