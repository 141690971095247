import React, { useState } from 'react';
import axios from 'axios';
import styles from './CrearProducto.module.css';

const CreateProductForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        images: [],
        price: '',
        stock: ''
    });

    const handleChange = async (e) => {
        const { name, value, files } = e.target;

        if (name === 'images') {
            const selectedImages = Array.from(files);
            const imagePromises = selectedImages.map(file => toBase64(file));
            const imageStrings = await Promise.all(imagePromises);

            setFormData({
                ...formData,
                images: [...formData.images, ...imageStrings]
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const handleRemoveImage = (index) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);
        setFormData({
            ...formData,
            images: updatedImages
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { name, description, images, price, stock } = formData;

        const data = {
            name,
            description,
            images, // Enviar el arreglo de cadenas base64
            price,
            stock
        };

       /*  console.log(data, ' esto se manda'); */ // Verificar los datos que contiene `data`
        try {
            const response = await axios.post('https://rezuma.onrender.com/Products', formData);

            if (response.status === 201) {
                alert('Producto creado con éxito');
                setFormData({
                    name: '',
                    description: '',
                    images: [],
                    price: '',
                    stock: ''
                });
            }
        } catch (error) {
            if (error.response) {
                console.error('Error de servidor:', error.response.data);
                alert('Error al crear el producto. Consulte la consola para más detalles.');
            } else if (error.request) {
                console.error('Error de solicitud:', error.request);
                alert('Error al crear el producto. No se recibió respuesta del servidor.');
            } else {
                console.error('Error:', error.message);
                alert('Error al crear el producto. Verifique su conexión o consulte la consola para más detalles.');
            }
        }
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <h2>Crear Producto</h2>
            <div className={styles.inputGroup}>
                <label htmlFor="name">Nombre:</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor="description">Descripción:</label>
                <textarea id="description" name="description" value={formData.description} onChange={handleChange} />
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor="images">Imágenes:</label>
                <input type="file" id="images" name="images" accept="image/*" multiple onChange={handleChange} />
                <div className={styles.imagePreview}>
                    {formData.images.map((image, index) => (
                        <div key={index} className={styles.imageContainer}>
                            <img src={image} alt={`Imagen ${index}`} className={styles.image} />
                            <button type="button" className={styles.removeButton} onClick={() => handleRemoveImage(index)}>Eliminar</button>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor="price">Precio:</label>
                <input type="number" id="price" name="price" value={formData.price} onChange={handleChange} />
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor="stock">Stock:</label>
                <input type="number" id="stock" name="stock" value={formData.stock} onChange={handleChange} />
            </div>
            <button type="submit" className={styles.button}>Confirmar Creación</button>
        </form>
    );
};

export default CreateProductForm;
