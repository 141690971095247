import styles from './SectionThree.module.css'
import three from '../../assets/3.avif'

const SectionThree = () => {
    return(
        <div className={styles.backgroundd}>  
            <img className={styles.image} src={three} alt="" loading='lazy'/>
            <section className={styles.section}>
                <p className={styles.title}>SOPAS DE AUTOR</p>
                <p className={styles.text}>
                    Te presentamos nuestra primer línea de productos:<br/>
                    prácticos kits de sopas de autor congeladas. <br/>
                    Podés elegir entre el Kit Temporada, Kit Gourmet  <br/>
                    o  Armá Tu Kit con 5 sopas a elección. 
                    <br/>
                </p>
                <p>Una re-solución para alimentar tu día a día.</p>
            </section>
        </div>  
    )
}

export default SectionThree;
